<div class="container mt-4" *ngIf="selectedClaim">
  <mat-card>
    <mat-toolbar color="primary" class="form-header">
      <h2 class="mat-title">Owner Request Details</h2>
    </mat-toolbar>
    <div class="row m-3">
      <div class="col-md-6 mb-3">
        <mat-label>Id:</mat-label>
        <p>{{ selectedClaim?.id }}</p>
      </div>
      <div class="col-md-6 mb-3">
        <mat-label>Owner Name:</mat-label>
        <p>{{ selectedClaim?.ownerName }}</p>
      </div>
      <div class="col-md-6 mb-3">
        <mat-label>Mobile Number:</mat-label>
        <p>{{ selectedClaim?.mobileNumber }}</p>
      </div>
      <div class="col-md-6 mb-3">
        <mat-label>Email:</mat-label>
        <p>{{ selectedClaim?.email }}</p>
      </div>
      <div class="col-md-6 mb-3">
        <mat-label>Flat Number:</mat-label>
        <p>{{ selectedClaim?.flatNumber }}</p>
      </div>
      <div class="col-md-6 mb-3" style="color: red">
        <mat-label>Purchase Type:</mat-label>
        <p>{{ selectedClaim?.purchaseType }}</p>
      </div>
      <div class="col-md-6 mb-3">
        <mat-label>Aadhar Number:</mat-label>
        <p>{{ selectedClaim?.aadharNumber }}</p>
      </div>
      <div class="col-12 mb-3 b-1">
        <mat-card-title>Bank Details</mat-card-title>
        <mat-card class="m-2 p-2">
          <div class="row">
            <div class="col-md-6 mb-3">
              <mat-label>Account Holder Name:</mat-label>
              <p>{{ selectedClaim?.accountHolderName }}</p>
            </div>
            <div class="col-md-6 mb-3">
              <mat-label>Account Number:</mat-label>
              <p>{{ selectedClaim?.accountNumber }}</p>
            </div>
            <div class="col-md-6 mb-3">
              <mat-label>Bank Name:</mat-label>
              <p>{{ selectedClaim?.bankName }}</p>
            </div>
            <div class="col-md-6 mb-3">
              <mat-label>Branch Bank Name:</mat-label>
              <p>{{ selectedClaim?.branch }}</p>
            </div>
            <div class="col-md-6 mb-3">
              <mat-label>IFSC Code:</mat-label>
              <p>{{ selectedClaim?.ifscCode }}</p>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="col-md-6 mb-3">
        <mat-label>Status:</mat-label>
        <ng-container *ngIf="user?.admin; else showStatus">
          <mat-form-field>
            <mat-select [(ngModel)]="selectedClaim.status">
              <mat-option
                *ngFor="let status of statusOptions"
                [value]="status"
                >{{ status | titlecase }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-template #showStatus>
          <p>{{ selectedClaim?.status || "Pending" | titlecase }}</p>
        </ng-template>
      </div>

      <div class="col-md-6 mb-3">
        <mat-label>Comments:</mat-label>
        <ng-container *ngIf="user?.admin; else showComments">
          <mat-form-field>
            <textarea
              class="w-100"
              rows="3"
              matInput
              [(ngModel)]="selectedClaim.comments"
              placeholder="Add comments here"
            ></textarea>
          </mat-form-field>
        </ng-container>
        <ng-template #showComments>
          <p>{{ selectedClaim?.comments || "N/A" }}</p>
        </ng-template>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div *ngIf="user?.admin">
      <div class="d-flex justify-content-center my-4">
        <button
          class="mx-2"
          mat-raised-button
          color="primary"
          (click)="onUpdateBtnClicked(selectedClaim)"
        >
          Update Status
        </button>
      </div>
      <mat-divider></mat-divider>
    </div>

    <div class="my-2" *ngIf="eSignRequest || viewDigitalDocs">
      <app-view-docs
        (signSubmittedBtnClicked)="signSubmittedBtnClicked($event)"
        [claim]="selectedClaim"
      ></app-view-docs>
    </div>

    <ng-container *ngTemplateOutlet="actionBtnView"></ng-container>

    <!-- ActionBtnView -->
    <ng-template #actionBtnView>
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <h2>Actions</h2>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="m-3 text-center">
            <!--Admin Button Action View-->
            <ng-container *ngIf="user?.admin">
              <ng-container *ngTemplateOutlet="uploadOptionView"></ng-container>
              <div
                class="d-flex justify-content-center align-items-center flex-wrap m-2"
              >
                <ng-container
                  *ngTemplateOutlet="adminActionBtnView"
                ></ng-container>
                <ng-container
                  *ngTemplateOutlet="defaultActionBtnView"
                ></ng-container>
              </div>
            </ng-container>

            <!--User Button Action View-->
            <ng-container *ngIf="!user?.admin">
              <ng-container *ngTemplateOutlet="uploadOptionView"></ng-container>

              <div
                class="d-flex justify-content-center align-items-center flex-wrap my-3"
              >
                <ng-container
                  *ngTemplateOutlet="userActionBtnView"
                ></ng-container>
                <ng-container
                  *ngTemplateOutlet="defaultActionBtnView"
                ></ng-container>
              </div>
            </ng-container>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-template>

    <ng-template #defaultActionBtnView>
      <button
        class="mx-2"
        mat-raised-button
        color="accent"
        (click)="onCloseBtnClicked()"
      >
        Close
      </button>
    </ng-template>
    <ng-template #adminActionBtnView>
      <button
        class="no-print"
        *ngIf="eSignEnabled"
        mat-raised-button
        color="primary"
        (click)="printUnsignedContents()"
      >
        Unsigned PDF Download
      </button>
    </ng-template>
    <ng-template #userActionBtnView>
      <button
        *ngIf="
          eSignEnabled &&
          !eSignRequest &&
          (selectedClaim?.status === 'reviewed' ||
            selectedClaim?.status === 'processed') &&
          !selectedClaim?.signedDocUrl
        "
        class="mx-2"
        mat-raised-button
        color="primary"
        (click)="onEsignRequest()"
      >
        eSign
      </button>

      <button
        class="no-print"
        *ngIf="eSignEnabled && signedImg && eSignRequest"
        mat-raised-button
        color="primary"
        (click)="printContents()"
      >
        Download PDF
      </button>

      <button
        class="mx-2"
        mat-raised-button
        color="warn"
        (click)="onDeleteRequest()"
      >
        Delete Request
      </button>
    </ng-template>
  </mat-card>
</div>

<ng-template #uploadOptionView>
  <ng-container>
    <div
      class="text-center upload-section"
      *ngIf="
        (selectedClaim?.status === 'reviewed' ||
          selectedClaim?.status === 'processed') &&
        !selectedClaim?.signedDocUrl
      "
    >
      <mat-label>Upload Signed Document:</mat-label>
      <div class="custom-file">
        <input
          type="file"
          class="form-control-file"
          id="signedDocUpload"
          (change)="onUploadSignedDocument($event)"
        />
      </div>
      <mat-spinner *ngIf="isUploading" diameter="30"></mat-spinner>
      <button
        class="mt-3"
        mat-raised-button
        color="primary"
        (click)="onUploadFile()"
      >
        Upload
      </button>
    </div>

    <div class="text-center upload-section" *ngIf="selectedClaim?.signedDocUrl">
      <div class="d-flex justify-content-center align-items-center">
        <a
          [href]="selectedClaim?.signedDocUrl"
          target="_blank"
          rel="noopener noreferrer"
          class="d-flex justify-content-center align-items-center"
        >
          View Signed Document
        </a>
        <button
          mat-icon-button
          color="warn"
          aria-label="Delete Signed Document"
          (click)="onDeleteSignedDocument()"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>
