export const environment = {
  production: true,
  path: "/ncltclaims/skbfowa",
  clientId: "skbfowa",
  baseUrl: "https://nclt.starkbfowa.com/api",
  eSignEnabled: true,
  firebaseConfig: {
    apiKey: "AIzaSyDG7tp4tUo9MzUPuEeNHAxvqMVF-T5EpOw",
    authDomain: "star-kbfowa.firebaseapp.com",
    projectId: "star-kbfowa",
    storageBucket: "star-kbfowa.appspot.com",
    messagingSenderId: "406772831223",
    appId: "1:406772831223:web:3462171b88b8172107d4f2",
    measurementId: "G-7TZHX1XC7H",
  },
};
